import axios from 'axios';
import config from '../config';

const { baseURL } = config.api;

export async function createCompetency(data) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios({
      method: 'post',
      data: data,
      url: `${baseURL}/admin/assessments/competency`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function updateCompetency(data, assessId) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios({
      method: 'patch',
      data: data,
      url: `${baseURL}/admin/assessments/competency/${assessId}`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function createUpskill(data) {
  const token = localStorage.getItem('token');
  try {
    const response = await axios({
      method: 'post',
      data: data,
      url: `${baseURL}/admin/assessments/upskill`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function updateUpskill(data, assessId) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios({
      method: 'patch',
      data: data,
      url: `${baseURL}/admin/assessments/upskill/${assessId}`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function createVetting(data) {
  const token = localStorage.getItem('token');
  try {
    const response = await axios({
      method: 'post',
      data: data,
      url: `${baseURL}/admin/assessments/vetting`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function updateVetting(data, assessId) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios({
      method: 'patch',
      data: data,
      url: `${baseURL}/admin/assessments/vetting/${assessId}`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}
