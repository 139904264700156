import { React, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useTheme, styled } from '@mui/material/styles';
import moment from 'moment';
import {
  Box,
  Tooltip,
  Typography,
  Card,
  Modal,
  Alert,
  AlertTitle,
  Grid,
  CardContent,
  TextField,
  Button,
  Dialog,
  DialogContent,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Delete, SettingsOutlined } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import {
  deleteLocation,
  deleteRequestType,
  updateLocation,
  updateRequestType,
} from '../../api';
import ConfirmDelete from './ConfirmDelete';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const style = {
  position: 'absolute',
  top: '50%',
  padding: 10,
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 2,
  pt: 2,
  px: 4,
  pb: 3,
  textAlign: 'center',
};

export default function Index(rowBody) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [rows, setRows] = useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [pageError, setPageError] = useState();
  const [itemId, setItemId] = useState();
  const [rowsName, setRowsName] = useState();
  const [pageMessage, setPageMessage] = useState('');
  // Avoid a layout jump when reaching the last page with empty rows.
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [deleteRow, setDeleteRow] = useState();
  const [updateName, setUpdateName] = useState('');

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (rowBody?.location) {
      rowBody?.location.sort((a, b) => {
        return new Date(b?.created_at) - new Date(a?.created_at);
      });
      setRows(rowBody?.location);
      setRowsName('location');
    } else {
      rowBody?.requestTypes.sort((a, b) => {
        return new Date(b?.created_at) - new Date(a?.created_at);
      });
      setRows(rowBody?.requestTypes);
      console.log(rowBody);
      setRowsName('requestTypes');
    }
  }, [rowBody]);

  const handleDialogOpen = (row) => {
    setConfirmOpen(true);
    setDeleteRow(row);
  };

  const handleDialogClose = () => {
    setDeleteRow(null);
    setConfirmOpen(false);
  };

  const handleDeleteItem = async (row) => {
    setLoading(true);
    let response;
    if (rowBody?.location) {
      response = await deleteLocation(row);
    } else {
      response = await deleteRequestType(row);
    }

    setLoading(false);
    if (response?.error) {
      const { error } = response;
      setPageError(error?.message);
      return;
    }

    if (!response?.success || response?.message) {
      setPageError(response?.message);
    }
    if (response?.success) {
      setPageMessage(
        'Successfully deleted a ' +
          (rowsName === 'location' ? 'location' : 'request type')
      );
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  };

  const handleUpdateItem = async (data) => {
    setLoading(true);
    let response;
    if (itemId) {
      if (rowBody?.location) {
        response = await updateLocation(itemId, data);
      } else {
        response = await updateRequestType(itemId, data);
      }
    }

    if (response?.error) {
      const { error } = response;
      setPageError(error?.message);
      return;
    }

    if (!response?.success || response?.message) {
      setPageError(response?.message);
    }
    if (response?.success) {
      setPageMessage('Successfully created a new location');
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const openUpdateModal = async (id, name) => {
    await setItemId(id);
    await setUpdateName(name);
    setOpen(true);
  };

  const closeUpdateModal = () => {
    setUpdateName('');
    reset();
    setOpen(false);
  };

  const userType = localStorage.getItem('user-role');

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
    // minWidth: 170,
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: 'rgba(255, 83, 24, 0.08)',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  }));

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {pageMessage && (
        <Alert severity="success" container>
          <AlertTitle>Success</AlertTitle>
          <strong>{pageMessage}</strong>
        </Alert>
      )}

      <TableContainer component={Paper} border={2} sx={{ minHeight: '100vh' }}>
        {rows?.length && (
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                {rowBody?.headers.map((column) => (
                  <StyledTableCell key={column}>
                    <Typography
                      variant="h6"
                      sx={{ fontSize: 16, fontWeight: 'bold' }}
                    >
                      {column !== 'Date Created' && column}
                    </Typography>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? rows?.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : rows
              ).map((row) => (
                <StyledTableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>

                  <ConfirmDelete
                    title="Delete request?"
                    open={row == deleteRow}
                    setOpen={handleDialogClose}
                    onConfirm={() => handleDeleteItem(row)}
                  >
                    Are you sure you want to delete this request?
                  </ConfirmDelete>
                  <TableCell component="th" scope="row">
                    <Tooltip title="Delete">
                      <IconButton disabled={userType !== `"super-admin"`}>
                        <Delete
                          // onClick={() => handleDialogOpen(row)}
                          color={
                            userType == `"super-admin"`
                              ? 'primary'
                              : 'secondary'
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Tooltip title="Update">
                      <IconButton disabled={userType !== `"super-admin"`}>
                        <EditIcon
                          onClick={() => openUpdateModal(row.id, row.name)}
                          color={
                            userType == `"super-admin"`
                              ? 'primary'
                              : 'secondary'
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </StyledTableRow>
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[15, 25, { label: 'All', value: -1 }]}
                  colSpan={3}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>
      <Modal
        open={open}
        onClose={closeUpdateModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={{ ...style, width: { lg: 600, md: 300 }, padding: 'auto' }}>
          {pageError && <Alert severity="error">{pageError}</Alert>}
          {pageMessage && <Alert severity="success">{pageMessage}</Alert>}
          <Grid mb={1}>
            {
              <Typography
                id="modal-modal-title"
                variant="h6"
                fontWeight="bold"
                component="span"
                color="primary"
              >
                Update
              </Typography>
            }
          </Grid>
          <form onSubmit={handleSubmit(handleUpdateItem)}>
            <CardContent sx={{ padding: 5 }}>
              <TextField
                variant="standard"
                label="Name"
                required={true}
                sx={{ width: '60%' }}
                defaultValue={updateName}
                {...register('name', {
                  required: {
                    value: true,
                    message: 'Field is required',
                  },
                })}
              />
              <Box mt={1}>
                <Typography variant="small" component="small" color="error">
                  {' '}
                  {errors.location?.message || errors?.requestType?.message}
                </Typography>
              </Box>
              {rowsName === 'requestTypes' && (
                <div>
                  {/* <TextField
                    variant="standard"
                    label="Description"
                    required={true}
                    sx={{ width: '60%' }}
                    {...register('description', {
                      required: {
                        value: true,
                        message: 'Field is required',
                      },
                    })}
                  /> */}
                  <Box mt={1}>
                    <Typography variant="small" component="small" color="error">
                      {' '}
                      {errors.location?.message || errors?.requestType?.message}
                    </Typography>
                  </Box>
                </div>
              )}
            </CardContent>
            <Grid mt={3}>
              <Button
                type="submit"
                size="large"
                variant="contained"
                sx={{ width: 300, margin: 'auto' }}
              >
                Submit
              </Button>
            </Grid>
          </form>
        </Card>
      </Modal>
    </>
  );
}
