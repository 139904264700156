import axios from 'axios';
import config from '../config';

const { baseURL } = config.api;

export async function exportUserData(param) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios.get(`${baseURL}/admin/users/${param}/export`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'We are unable to get you registered. Please try again or contact us support@fundis.com'
            : data.message,
      },
      errors: data.data,
    };
  }
}

export async function exportAdminData(param) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios.get(`${baseURL}/admin/${param}/export`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'We are unable to get you registered. Please try again or contact us support@fundis.com'
            : data.message,
      },
      errors: data.data,
    };
  }
}

export async function exportTransactionsData(param) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios.get(
      `${baseURL}/admin/${param}/export?status=SUCCESSFUL`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      }
    );
    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'We are unable to get you registered. Please try again or contact us support@fundis.com'
            : data.message,
      },
      errors: data.data,
    };
  }
}
