import { Box, Typography, Grid } from '@mui/material';
import SignInForm from './SignInForm';
import Logo from '../Logo/Logo';

function AuthForm() {
  return (
    <>
      <Logo />
      <Grid maxWidth="1200px" sx={{ margin: 'auto' }} p={2} container>
        <Grid sm={8} md={5} lg={6} item>
          <>
            <Typography
              variant="h3"
              component="h1"
              mt={8}
              mb={4}
              maxWidth="95%"
              lineHeight="1"
              fontWeight="bold"
            >
              Welcome <br />
              <Typography variant="h5" component="span">
                Fundis Admin Portal
              </Typography>
            </Typography>
          </>
        </Grid>
        <Grid
          xs={12}
          sm={10}
          md={7}
          lg={6}
          marginTop={2}
          item
          sx={{ backgroundColor: 'white' }}
        >
          <Box
            px={3}
            py={4}
            boxShadow="8px 8px 16px 4px rgba(0,0,0,0.15)"
            borderRadius={3}
          >
            <Typography variant="h5" component="span" color="primary">
              Sign In
            </Typography>
            <SignInForm />
            {/* <Grid mb={3}>
              <Button size="large" variant="outlined" fullWidth>
                <Grid component="span" mr={2}>
                  <img src={FacebookIcon} alt="Facebook Icon" />
                </Grid>
                <Grid component="span">Continue with Facebook</Grid>
              </Button>
            </Grid>
            <Grid mb={3}>
              <Button size="large" variant="outlined" fullWidth>
                <Grid component="span" mr={2}>
                  <img src={GoogleIcon} alt="Google Icon" />
                </Grid>
                <Grid component="span">Continue with Google</Grid>
              </Button>
            </Grid> */}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default AuthForm;
