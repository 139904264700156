import { Grid } from '@mui/material';

import { Table, Loader } from '../index';

export default function Index({ requestTypes }) {
  return (
    <Grid columnSpacing={4} container sx={{ marginLeft: '5px', width: '100%' }}>
      {requestTypes?.length > 0 ? (
        <Table
          requestTypes={requestTypes}
          headers={['Name', 'ID', 'Date Created']}
        />
      ) : (
        <Loader />
      )}
    </Grid>
  );
}
