import { default as React } from 'react';
import { Layout } from '../components';
import { Outlet } from 'react-router-dom';

function Dashboard() {
  return (
    <>
      <Layout>
        <Outlet />
      </Layout>
    </>
  );
}

export default Dashboard;
