import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Grid,
  LinearProgress,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { fetchOneArtisan, fetchOneCustomer, getInvoice } from '../../api';
import Loader from '../Loader';
import moment from 'moment';
import { formatter } from '../../lib/utils';

function ReceiptDetail(props) {
  const { receipt } = props;
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState({});
  const [artisan, setArtisan] = useState({});
  const [invoice, setInvoice] = useState([]);

  const checkCustomer = useCallback(
    async (id) => {
      setLoading(true);
      let response;
      if (id) {
        response = await fetchOneCustomer(id);
      }
      setLoading(false);

      if (response?.success && response?.data) {
        setCustomer(response?.data);
      }
    },
    [receipt]
  );

  const checkArtisan = useCallback(
    async (id) => {
      setLoading(true);
      let response;
      if (id) {
        response = await fetchOneArtisan(id);
      }
      setLoading(false);

      if (response?.success && response?.data) {
        setArtisan(response?.data);
      }
    },
    [receipt]
  );

  //getPayment to know if installmental or single payment
  const getPayment = useCallback(
    async (id) => {
      setLoading(true);
      let response;
      if (id) {
        response = await getInvoice(id);
      }
      setLoading(false);

      if (response?.success && response?.data) {
        setInvoice(response?.data?.transaction);
      }
    },
    [receipt]
  );

  useEffect(() => {
    if (receipt?.paid_by !== null) {
      checkCustomer(receipt?.paid_by);
    }
    if (receipt?.paid_to !== null) {
      checkArtisan(receipt?.paid_to);
    }
    if (receipt.invoice_id !== null) {
      getPayment(receipt?.invoice_id);
    }
  }, [receipt]);

  return (
    <Box>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Box alignItems="center" justifyContent="center" display="flex">
            <Typography
              id="modal-modal-title"
              variant="h5"
              fontWeight="bold"
              component="span"
              color="primary"
            >
              Receipt Detail
            </Typography>
          </Box>

          <Grid container>
            <Grid item lg={8} sm={12}>
              <Typography variant="h6">
                Status:{' '}
                <Typography
                  variant="p"
                  color={
                    receipt?.status === 'accepted'
                      ? 'status.success'
                      : 'primary'
                  }
                >
                  {receipt?.status === 'accepted'
                    ? 'Accepted'
                    : receipt?.status}
                </Typography>
              </Typography>
            </Grid>
          </Grid>

          <Grid lg={12}>
            <LinearProgress
              sx={{ borderRadius: 5, height: 2 }}
              value={'100%'}
              variant={'determinate'}
            />
          </Grid>

          <Grid container mt={1}>
            <Grid item lg={6} md={6} sm={6} display="flex" flexDirection="row">
              <Typography
                variant="p"
                color="primary"
                pt={3}
                pl={'3px'}
                sx={{ float: 'left' }}
              >
                Customer:
                <Typography color="secondary" variant="span" pl={1}>
                  {Object.keys(customer).length > 0
                    ? customer?.first_name + ' ' + customer?.last_name
                    : 'Customer not available'}
                </Typography>
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} display="flex" flexDirection="row">
              <Typography
                variant="p"
                color="primary"
                pt={3}
                pl={'3px'}
                sx={{ float: 'left' }}
              >
                Artisan:
                <Typography color="secondary" variant="span" pl={1}>
                  {Object.keys(artisan).length
                    ? artisan?.first_name + ' ' + artisan?.last_name
                    : 'Artisan not available'}
                </Typography>
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} display="flex" flexDirection="row">
              <Typography
                variant="p"
                color="primary"
                pt={3}
                pl={'3px'}
                sx={{ float: 'left' }}
              >
                Description:
                <Typography color="secondary" variant="span" pl={1}>
                  {receipt?.description}
                </Typography>
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} display="flex" flexDirection="row">
              <Typography
                variant="p"
                color="primary"
                pt={3}
                pl={'3px'}
                sx={{ float: 'left' }}
              >
                Reference:
                <Typography color="secondary" variant="span" pl={1}>
                  {receipt?.reference || 'N/A'}
                </Typography>
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} display="flex" flexDirection="row">
              <Typography
                variant="p"
                color="primary"
                pt={3}
                pl={'3px'}
                sx={{ float: 'left' }}
              >
                Created:
                <Typography color="secondary" variant="span" pl={1}>
                  {moment(receipt?.created_at).format('ll')}
                </Typography>
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} display="flex" flexDirection="row">
              <Typography
                variant="p"
                color="primary"
                pt={3}
                pl={'3px'}
                sx={{ float: 'left' }}
              >
                Updated:
                <Typography color="secondary" variant="span" pl={1}>
                  {moment(receipt?.updated_at).format('ll')}
                </Typography>
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} display="flex" flexDirection="row">
              <Typography
                variant="p"
                color="primary"
                pt={3}
                pl={'3px'}
                sx={{ float: 'left' }}
              >
                Settled Amount:
                <Typography color="secondary" variant="span" pl={1}>
                  {formatter.format(receipt?.settled_amount)}
                </Typography>
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} display="flex" flexDirection="row">
              <Typography
                variant="p"
                color="primary"
                pt={3}
                pl={'3px'}
                sx={{ float: 'left' }}
              >
                Split Amount:
                <Typography color="secondary" variant="span" pl={1}>
                  {Number(receipt?.split_amount).toFixed(2) || 'N/A'}
                </Typography>
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} display="flex" flexDirection="row">
              <Typography
                variant="p"
                color="primary"
                pt={3}
                pl={'3px'}
                sx={{ float: 'left' }}
              >
                Split Percentage:
                <Typography color="secondary" variant="span" pl={1}>
                  {receipt?.split_percentage || '0'}%
                </Typography>
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} display="flex" flexDirection="row">
              <Typography
                variant="p"
                color="primary"
                pt={3}
                pl={'3px'}
                sx={{ float: 'left' }}
              >
                Transaction Type:
                <Typography color="secondary" variant="span" pl={1}>
                  {invoice?.length > 1
                    ? 'Installmental payment'
                    : 'Full amount'}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
          {/* <Accordion sx={{ marginTop: '10px' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color="primary">
                {invoice.length > 1
                  ? 'Installmental payment details'
                  : 'Full amount details'}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {invoice.map((trans, i) => {
                return (
                  <Grid container mt={1} key={trans.id}>
                    <Grid item lg={1} sm={1} display="flex" flexDirection="row">
                      <Typography>{i + 1}.</Typography>
                    </Grid>
                    <Grid item lg={5} sm={5} display="flex" flexDirection="row">
                      <Typography
                        variant="p"
                        color="primary"
                        sx={{ float: 'left' }}
                      >
                        Amount:
                        <Typography color="secondary" variant="span" pl={1}>
                          {trans.amount}
                        </Typography>
                      </Typography>
                    </Grid>

                    <Grid item lg={6} sm={6} display="flex" flexDirection="row">
                      <Typography
                        variant="p"
                        color="primary"
                        sx={{ float: 'left' }}
                      >
                        Date of payment:
                        <Typography color="secondary" variant="span" pl={1}>
                          {moment(trans?.created_at).format('ll')}
                        </Typography>
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </AccordionDetails>
          </Accordion> */}
        </>
      )}
    </Box>
  );
}

export default ReceiptDetail;
