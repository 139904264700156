import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Modal, Grid, Button } from '@mui/material';
import moment from 'moment';

import { exportAdminData, fetchAllQuotations, fetchQuotation } from '../api';
import { Quotation, DataGrid } from '../components';
import useMediaQuery from '@mui/material/useMediaQuery';

function Index() {
  const [pageSize, setPageSize] = useState(15);
  const [rowCount, setRowCount] = useState(15);

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([]);

  const [openQuotationDetails, setOpenQuotationDetails] = useState(false);
  const [checkQuotation, setcheckQuotation] = useState([]);

  const handleCloseQuotationDetails = () => setOpenQuotationDetails(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    overflowY: 'scroll',
    pt: 2,
    px: 4,
    pb: 3,
    textAlign: 'left',
  };

  const mobileStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    overflowY: 'scroll',
    maxHeight: '70vh',
    width: '80vw',
    minHeight: '30vh',
    bgcolor: 'background.paper',
    margin: '10px',
    borderRadius: 2,
    boxShadow: 24,
    p: 2,
  };

  const navigate = useNavigate();

  const columns = [
    { field: 'reference', headerName: 'Reference', width: 300 },
    {
      field: 'status',
      headerName: 'Status',
      width: 300,
    },
    {
      field: 'date_created',
      headerName: 'Created Date',
      width: 200,
    },
    {
      field: 'date_updated',
      headerName: 'Updated Date',
      width: 160,
    },
  ];

  const getOneRequest = async (id) => {
    setLoading(true);
    let response;
    if (id) {
      response = await fetchQuotation(id);
    }
    setLoading(false);

    if (response?.success && response?.data) {
      setcheckQuotation(response?.data);
    }
  };

  const handleOpenQuotationDetails = (id) => {
    if (id) {
      getOneRequest(id);
    }
    setOpenQuotationDetails(true);
  };

  const getQuotation = async (page) => {
    setLoading(true);
    const response = await fetchAllQuotations(page + 1);
    setLoading(false);

    if (response?.success && response?.data?.data) {
      const { data } = response;
      const rows = data?.data.map((customerData) => ({
        ...customerData,
        date_created: moment(customerData?.created_at).format('ll'),
        date_updated: moment(customerData?.updated_at).format('ll'),
      }));

      setRowData(rows);
      setRowCount(data?.total);
    }
  };

  const getPage = async (page) => {
    await getQuotation(page);
    setPage(page);
  };

  const handleExport = async () => {
    setLoading(true);

    const response = await exportAdminData('quotations');

    const a = document.createElement('a');
    const date = moment().format('YYYY-MM-DD');

    a.download = `AllQuotations_export_output_${date}.csv`;
    a.href = response?.data?.report;

    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();

    setLoading(false);
  };

  useEffect(() => {
    getQuotation(page);
  }, [page]);

  const requiredWidth = useMediaQuery('(min-width:1024px)');

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      maxWidth={{ lg: 'lg', xl: 'lg' }}
      p={5}
      minHeight="100vh"
      container
      flexGrow={1}
    >
      <Grid container>
        <Grid item lg={6}>
          <Typography variant="h5" mb={3}>
            All Quotations
          </Typography>
        </Grid>
        <Grid item lg={6}>
          <Button
            variant="outlined"
            sx={{ width: 300, float: 'right' }}
            onClick={() => handleExport()}
          >
            {' '}
            Export{' '}
          </Button>
        </Grid>
      </Grid>
      <Box width="100%">
        <DataGrid
          rowCount={rowCount}
          rows={rowData}
          columns={columns}
          loading={loading}
          onRowClick={(row) => handleOpenQuotationDetails(row?.id)}
          pageSize={pageSize}
          onPageChange={getPage}
          page={page}
          autoHeight
          rowsPerPageOptions={[15]}
          paginationMode="server"
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
        />
        <Modal
          open={openQuotationDetails}
          onClose={handleCloseQuotationDetails}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={requiredWidth ? style : mobileStyle}>
            <Quotation quotation={checkQuotation} />
          </Box>
        </Modal>
      </Box>
    </Box>
  );
}

export default Index;
