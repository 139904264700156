import {
  View,
  Page,
  Document,
  Image,
  Text,
  PDFDownloadLink,
  Svg,
  G,
  P,
  Path,
  Polygon,
  PDFViewer,
} from '@react-pdf/renderer';
import { Button } from '@mui/material';
import { useState, useEffect } from 'react';
import { styles } from './pdfstyle';
import DownloadIcon from '@mui/icons-material/Download';

const ArtisanPDF = (props) => {
  const { profile } = props;

  const [vetted, setVetted] = useState(false);

  useEffect(() => {
    if (
      profile?.profile?.competencyAssessments?.length >= 1 &&
      profile?.profile?.vettingReport?.length >= 1
    ) {
      setVetted(true);
    }
  }, [profile]);

  return (
    <Document title={'FUNDIS Artisan Profile'}>
      <Page style={styles.body} wrap size="A4">
        <Text style={styles.header} fixed>
          ~ FUNDIS ~
        </Text>
        <View style={styles.mainname}>
          <Text style={styles.titlel}>{profile?.profile?.first_name}</Text>
          <Text style={styles.titler}>{profile?.profile.last_name}</Text>
        </View>

        <Text style={styles.author}>Fundis Professional</Text>

        <View style={styles.container}>
          <View style={styles.detailColumn}>
            <Text style={styles.name}>PROFILE SUMMARY</Text>
            <Text style={styles.subtitle}>
              I am a qualified and professional artisan with over 3 years of
              experience. {vetted && 'I am assessed and vetted by FUNDIS.'}
            </Text>
          </View>
        </View>

        {profile?.profile?.skills?.length > 0 && (
          <View style={styles.container}>
            <View style={styles.detailColumn}>
              <Text style={styles.name}>SKILLS</Text>

              {profile?.profile?.skills.map((skill) => (
                <View style={styles.item}>
                  <Text style={styles.bulletPoint}>•</Text>
                  <Text style={styles.itemContent}>{skill.name}</Text>
                </View>
              ))}
            </View>
          </View>
        )}

        {profile?.profile?.qualifications?.institution && (
          <View style={styles.container}>
            <View style={styles.detailColumn}>
              <Text style={styles.name}>EDUCATION</Text>
              <Text style={styles.etitle}>
                {profile?.profile?.qualifications?.institution}
              </Text>
              <Text style={styles.edusubtitle}>
                ({profile?.profile?.qualifications?.certificate})
              </Text>
            </View>
          </View>
        )}

        {profile?.profile?.references?.length > 0 && (
          <View style={styles.container}>
            <View style={styles.detailColumn}>
              <Text style={styles.name}>PAST PROJECTS</Text>

              {profile?.profile?.references?.map(function (reference, index) {
                return (
                  <View style={styles.oneproject}>
                    <Text style={styles.subtitle}>
                      {reference?.skill?.name} - {reference.location}
                    </Text>
                    <Text style={styles.subtitle}>
                      CLIENT NAME: {reference.name}
                    </Text>
                  </View>
                );
              })}
            </View>
          </View>
        )}
      </Page>
    </Document>
  );
};

const DownloadArtisanPdf = (props) => {
  const { artisandata } = props;

  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return (
    <>
      {hasMounted && (
        <PDFDownloadLink
          document={<ArtisanPDF profile={artisandata} />}
          fileName={`Artisan Profile_${
            artisandata?.profile?.first_name +
            ' ' +
            artisandata?.profile.last_name
          }`}
          style={{
            textDecoration: 'none',
          }}
        >
          {({ loading, error, url }) =>
            !loading &&
            !error &&
            url && (
              <Button
                variant="outlined"
                style={{
                  textDecoration: 'none',
                  margin: 4,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                startIcon={<DownloadIcon />}
              >
                Download {artisandata?.profile?.first_name} Profile
              </Button>
            )
          }
        </PDFDownloadLink>
      )}
    </>
  );
};

export default DownloadArtisanPdf;
