import axios from 'axios';
import config from '../config';

const { baseURL } = config.api;

export async function registerCustomer(customerData) {
  const { firstName, lastName, phone, email, password } = customerData;
  const customer = {
    first_name: firstName,
    last_name: lastName,
    phone,
    email,
    password,
    user_type: 'admin',
    confirm_password: password,
  };

  try {
    const response = await axios.post(`${baseURL}/register`, customer);
    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'We are unable to get you registered. Please try again or contact us support@fundis.com'
            : data.message,
      },
      errors: data.data,
    };
  }
}

export async function loginCustomer(customerData) {
  const { username, password } = customerData;
  const customer = {
    username,
    password,
    user_type: 'admin',
  };

  try {
    const response = await axios.post(`${baseURL}/login`, customer);
    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'We are unable to log you in now. Please try again or contact us support@fundis.com'
            : data.message,
      },
      errors: data.data,
    };
  }
}

export async function forgotPassword(customerPhoneNumber) {
  const { phone } = customerPhoneNumber;
  const customer = {
    phone,
  };

  try {
    const response = await axios.post(`${baseURL}/forgot-password`, customer);
    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'We are unable to reset your password now. Please try again or contact us support@fundis.com'
            : data.message,
      },
      errors: data.data,
    };
  }
}

export async function resetPassword(customerPhoneNumber) {
  const { password, confirmPassword: confirm_password } = customerPhoneNumber;

  const otp = JSON.parse(localStorage.getItem('otp'))?.otp;
  const phoneNumber = JSON.parse(localStorage.getItem('phoneNumber'))?.phone;

  const customer = {
    password,
    confirm_password,
    otp,
  };

  try {
    const response = await axios.post(
      `${baseURL}/reset-password/${phoneNumber}`,
      customer
    );
    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'We are unable to reset your password now. Please try again or contact us support@fundis.com'
            : data.message,
      },
      errors: data.data,
    };
  }
}

export async function verifyOTP(user_otp) {
  const { otp } = user_otp;
  const access_token = JSON.parse(localStorage.getItem('signup_token'));

  const customer = {
    otp,
  };

  try {
    const response = await axios.post(`${baseURL}/otp/verify`, customer, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'We are unable to reset your password now. Please try again or contact us support@fundis.com'
            : data.message,
      },
      errors: data.data,
    };
  }
}

export async function resendOTP() {
  const access_token = JSON.parse(localStorage.getItem('signup_token'));

  let data;

  try {
    const response = await axios.post(`${baseURL}/otp/resend`, data, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'We are unable to reset your password now. Please try again or contact us support@fundis.com'
            : data.message,
      },
      errors: data.data,
    };
  }
}
