import React from 'react';
import { Link } from 'react-router-dom';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  Grid,
  IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  listItem: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  viewButton: {
    marginRight: theme.spacing(1),
    '& svg': {
      fontSize: 20,
    },
  },
}));

const FileAccordion = ({ artisanDetails }) => {
  const classes = useStyles();

  return (
    <Accordion className={classes.accordion} sx={{ marginTop: '10px' }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={classes.summary}
      >
        <Typography color="primary">Artisan Files:</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List>
          {artisanDetails && artisanDetails?.user_files.length ? (
            artisanDetails?.user_files?.map((userFiles, index) => (
              <ListItem className={classes.listItem} key={index}>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <IconButton
                      href={userFiles?.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.viewButton}
                    >
                      <Typography color="primary" component="span" m={1}>
                        View File
                      </Typography>
                      <VisibilityIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Typography color="textSecondary" variant="body2">
                      Created At:{' '}
                      <Typography
                        color="primary"
                        variant="span"
                        component="span"
                      >
                        {moment(userFiles?.created_at).format('MMM DD, YYYY') ||
                          'N/A'}
                      </Typography>
                    </Typography>
                  </Grid>
                  {/* <Grid item>
                    <IconButton
                      component={Link}
                      to={`/file-details/${userFiles?.id}`} // Change the path according to your route configuration
                      target='_blank'
                      rel='noopener noreferrer'
                      className={classes.viewButton}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Grid> */}
                </Grid>
              </ListItem>
            ))
          ) : (
            <Typography color="secondary">N/A</Typography>
          )}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default FileAccordion;
