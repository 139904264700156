import axios from 'axios';
import config from '../config';

const { baseURL } = config.api;

export async function fetchQuotation(id) {
  const token = localStorage.getItem('token');
  try {
    const response = await axios({
      method: '',
      url: `${baseURL}/admin/quotations/${id}`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function getReceipt(id) {
  const token = localStorage.getItem('token');
  try {
    const response = await axios({
      method: '',
      url: `${baseURL}/admin/transactions/${id}`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function getInvoice(id) {
  const token = localStorage.getItem('token');
  try {
    const response = await axios({
      method: '',
      url: `${baseURL}/admin/quotations/${id}`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}
