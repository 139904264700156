import {
  Grid,
  Typography,
  Box,
  Button,
  Link as MUILink,
  useMediaQuery,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import Logo from '../components/Logo/Logo';

function FourOFour() {
  const largeScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));

  return (
    <>
      <Logo />
      <Grid
        maxWidth="1200px"
        sx={{ margin: 'auto', backgroundColor: 'white' }}
        p={2}
        container
        borderRadius={3}
      >
        <Grid xs={12} sm={10} md={7} lg={6} marginTop={2} item>
          <Box px={3} py={4}>
            <img
              src="/error404.jpg"
              width="100%"
              height="68%"
              style={{ objectFit: 'cover' }}
              alt="Fundis Logo"
            />
          </Box>
        </Grid>

        <Grid
          sm={8}
          md={5}
          lg={6}
          item
          display={'flex'}
          alignItems="center"
          justifyContent={'center'}
          textAlign={'center'}
          flexDirection="column"
        >
          <Typography variant="h4" component="h1" mt={8} mb={4} maxWidth="95%">
            We can't seem to find a page you are looking for
          </Typography>
          <Button
            size="large"
            variant={'contained'}
            sx={{ px: 10 }}
            type="submit"
          >
            <MUILink
              underline="none"
              component={NavLink}
              to="/"
              color="inherit"
            >
              Back to home
            </MUILink>
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default FourOFour;
