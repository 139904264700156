import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Grid, LinearProgress } from '@mui/material';
import { fetchOneArtisan, fetchOneCustomer } from '../../api';
import Loader from '../Loader';
import moment from 'moment';

function QuotationDetail(props) {
  const { quotation } = props;
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState([]);
  const [artisan, setArtisan] = useState([]);

  const checkCustomer = useCallback(
    async (id) => {
      setLoading(true);
      let response;
      if (id) {
        response = await fetchOneCustomer(id);
      }
      setLoading(false);

      if (response?.success && response?.data) {
        setCustomer(response?.data);
      }
    },
    [quotation]
  );

  const checkArtisan = useCallback(
    async (id) => {
      setLoading(true);
      let response;
      if (id) {
        response = await fetchOneArtisan(id);
      }
      setLoading(false);

      if (response?.success && response?.data) {
        setArtisan(response?.data);
      }
    },
    [quotation]
  );

  useEffect(() => {
    checkCustomer(quotation?.customer_id);
    checkArtisan(quotation?.artisan_id);
  }, [quotation]);

  return (
    <Box>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Box alignItems="center" justifyContent="center" display="flex">
            <Typography
              id="modal-modal-title"
              variant="h5"
              fontWeight="bold"
              component="span"
              color="primary"
            >
              Quotation Detail
            </Typography>
          </Box>

          <Grid container>
            <Grid item lg={8} sm={12}>
              <Typography variant="h6">
                Status:{' '}
                <Typography
                  variant="p"
                  color={
                    quotation?.status === 'accepted'
                      ? 'status.success'
                      : 'primary'
                  }
                >
                  {quotation?.status === 'accepted'
                    ? 'Accepted'
                    : quotation?.status}
                </Typography>
              </Typography>
            </Grid>
          </Grid>

          <Grid lg={12}>
            <LinearProgress
              sx={{ borderRadius: 5, height: 2 }}
              value={'100%'}
              variant={'determinate'}
            />
          </Grid>

          <Grid container mt={1}>
            <Grid item lg={6} md={6} sm={6} display="flex" flexDirection="row">
              <Typography
                variant="p"
                color="primary"
                pt={3}
                pl={'3px'}
                sx={{ float: 'left' }}
              >
                Customer:
                <Typography color="secondary" variant="span" pl={1}>
                  {customer?.first_name + ' ' + customer?.last_name || 'N/A'}
                </Typography>
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} display="flex" flexDirection="row">
              <Typography
                variant="p"
                color="primary"
                pt={3}
                pl={'3px'}
                sx={{ float: 'left' }}
              >
                Artisan:
                <Typography color="secondary" variant="span" pl={1}>
                  {artisan?.first_name + ' ' + artisan?.last_name || 'N/A'}
                </Typography>
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} display="flex" flexDirection="row">
              <Typography
                variant="p"
                color="primary"
                pt={3}
                pl={'3px'}
                sx={{ float: 'left' }}
              >
                Reference:
                <Typography color="secondary" variant="span" pl={1}>
                  {quotation?.reference || 'N/A'}
                </Typography>
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} display="flex" flexDirection="row">
              <Typography
                variant="p"
                color="primary"
                pt={3}
                pl={'3px'}
                sx={{ float: 'left' }}
              >
                Created:
                <Typography color="secondary" variant="span" pl={1}>
                  {moment(quotation?.created_at).format('ll')}
                </Typography>
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} display="flex" flexDirection="row">
              <Typography
                variant="p"
                color="primary"
                pt={3}
                pl={'3px'}
                sx={{ float: 'left' }}
              >
                Updated:
                <Typography color="secondary" variant="span" pl={1}>
                  {moment(quotation?.updated_at).format('ll')}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
}

export default QuotationDetail;
