import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Modal, Grid, Button, Tooltip } from '@mui/material';
import { getAllClaims } from '../api';
import { Artisan, DataGrid } from '../components';
import moment from 'moment';
import useMediaQuery from '@mui/material/useMediaQuery';
import { capitalizeInitialLetter } from '../lib/utils';

function Index() {
  const [pageSize, setPageSize] = useState(15);
  const [rowCount, setRowCount] = useState(15);

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    maxHeight: '70vh',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
    textAlign: 'left',
  };

  const mobileStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    minHeight: '30vh',
    maxHeight: '70vh',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    margin: '10px',
    borderRadius: 2,
    boxShadow: 24,
    p: 2,
  };

  const navigate = useNavigate();

  const columns = [
    {
      field: 'covertype',
      headerName: 'Cover Type',
      width: 300,
    },

    {
      field: 'artisanname',
      headerName: "Artisan's Name",
      width: 400,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 200,
      renderCell: (params) => {
        if (params?.row?.status === 'Approved') {
          return (
            <Tooltip
              bgcolor="green"
              p="5px"
              color="white"
              borderRadius="3px"
              title={'Insurance approved'}
            >
              <Box>Approved</Box>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              bgcolor="gray"
              p="5px"
              color="white"
              borderRadius="3px"
              title={'Insurance awaiting approval'}
            >
              <Box>Pending</Box>
            </Tooltip>
          );
        }
      },
    },

    {
      field: 'notifyartisan',
      headerName: 'Artisan Notified',
      width: 200,
    },
  ];

  const getArtisansClaims = async (page) => {
    setLoading(true);
    const response = await getAllClaims(page + 1);
    console.log(response);
    setLoading(false);

    if (response?.success && response?.data?.data) {
      const { data } = response;
      const rows = data?.data.map((insuranceData) => ({
        ...insuranceData,
        covertype: `${capitalizeInitialLetter(insuranceData?.type)}`,
        artisanname: `${
          capitalizeInitialLetter(insuranceData?.artisan?.first_name) || 'Not '
        } ${
          capitalizeInitialLetter(insuranceData?.artisan?.last_name) ||
          'available'
        }`,
        status: `${capitalizeInitialLetter('Pending')}`,
        notifyartisan: `${
          insuranceData?.sent === 1 ? 'Email sent' : 'Not yet'
        }`,
      }));

      setRowData(rows);
      setRowCount(data?.total);
    }
  };

  const getPage = async (page) => {
    await getArtisansClaims(page);
    setPage(page);
  };

  useEffect(() => {
    getArtisansClaims(page);
  }, [page]);

  const requiredWidth = useMediaQuery('(min-width:1024px)');

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      maxWidth={{ lg: 'lg', xl: 'lg' }}
      p={5}
      minHeight="100vh"
      container
      flexGrow={1}
    >
      <Grid container>
        <Grid item lg={6}>
          <Typography variant="h5" mb={3}>
            Claims by Artisans
          </Typography>
        </Grid>
      </Grid>
      <Box width="100%">
        <DataGrid
          rowCount={rowCount}
          rows={rowData}
          columns={columns}
          loading={loading}
          pageSize={pageSize}
          onPageChange={getPage}
          page={page}
          autoHeight
          rowsPerPageOptions={[30]}
          paginationMode="server"
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
        />
      </Box>
    </Box>
  );
}

export default Index;
