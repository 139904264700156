import { Box } from '@mui/material';
import React from 'react';
import { MenuBar } from '../';

function Layout({ children }) {
  return (
    <>
      <MenuBar />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          bgcolor: 'background.default',
        }}
        maxWidth={{ md: '75vw', lg: '80vw', xl: '85vw' }}
        marginLeft={{ md: 'auto' }}
        component="main"
      >
        {children}
      </Box>
    </>
  );
}

export default Layout;
