import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Box,
  Modal,
  Grid,
  Button,
  Alert,
  Switch,
  Tooltip,
} from '@mui/material';
import {
  fetchAllArtisans,
  fetchOneArtisan,
  exportUserData,
  deactivateOneArtisan,
  activateOneArtisan,
  deleteOneArtisan,
} from '../api';
import { Artisan, DataGrid } from '../components';
import moment from 'moment';
import useMediaQuery from '@mui/material/useMediaQuery';
import DeleteIcon from '@mui/icons-material/Delete';

function Index() {
  const [pageSize, setPageSize] = useState(15);
  const [rowCount, setRowCount] = useState(15);

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([]);

  const [openArtisanDetails, setOpenArtisanDetails] = useState(false);
  const [checkArtisan, setCheckArtisan] = useState([]);
  const [artisanStatus, setArtisanStatus] = useState(false);
  const [pageMessage, setPageMessage] = useState('');
  const [pageError, setPageError] = useState();

  const handleCloseArtisanDetails = () => setOpenArtisanDetails(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    maxHeight: '70vh',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
    textAlign: 'left',
  };

  const mobileStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    minHeight: '30vh',
    maxHeight: '70vh',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    margin: '10px',
    borderRadius: 2,
    boxShadow: 24,
    p: 2,
  };

  const navigate = useNavigate();

  const columns = [
    { field: 'name', headerName: 'Name', width: 200 },
    {
      field: 'phone',
      headerName: 'Phone Number',
      width: 200,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 200,
    },
    {
      field: 'skill',
      headerName: 'Skill',
      description: 'date',
      width: 160,
    },
    {
      field: 'assessments',
      headerName: 'Assessments',
      description: 'date',
      width: 160,
    },
    {
      field: 'action',
      headerName: 'Account Status',
      sortable: true,
      width: 160,
      renderCell: (params) => {
        const onClickDeactivate = async (e) => {
          e.stopPropagation(); // don't select this row after clicking

          await deactivateCustomer(params?.id);
          setTimeout(() => window.location.reload(), 2000);
        };

        const onClickActivate = async (e) => {
          e.stopPropagation(); // don't select this row after clicking

          await activateCustomer(params?.id);
          setTimeout(() => window.location.reload(), 2000);
        };

        if (params?.row?.is_active === true) {
          return (
            <Tooltip title={'Deactivate this account'}>
              <Switch
                onClick={onClickDeactivate}
                defaultChecked
                color="warning"
              />
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title={'Activate this account'}>
              <Switch onClick={onClickActivate} color="warning" />
            </Tooltip>
          );
        }
      },
    },
    {
      field: 'delete',
      headerName: 'Delete account',
      sortable: true,
      width: 160,
      renderCell: (params) => {
        const onClickDelete = async (e) => {
          e.stopPropagation(); // don't select this row after clicking
          await deleteCustomer(params?.id);

          setTimeout(() => window.location.reload(), 2000);
        };

        return (
          <Tooltip title={'Delete this account'}>
            <DeleteIcon color="primary" onClick={onClickDelete} />
          </Tooltip>
        );
      },
    },
  ];

  const deactivateCustomer = async (id) => {
    setLoading(true);
    const response = await deactivateOneArtisan(id);
    setLoading(false);

    if (response?.success) {
      setPageMessage('User account has been deactivated.');
    } else {
      setPageError('There is a problem with this account');
    }
  };

  const activateCustomer = async (id) => {
    setLoading(true);
    const response = await activateOneArtisan(id);
    setLoading(false);

    if (response?.success) {
      setPageMessage('User account has been reactivated.');
    } else {
      setPageError('You are unable to activate this account at the moment.');
    }
  };

  const deleteCustomer = async (id) => {
    setLoading(true);

    const response = await deleteOneArtisan(id);
    setLoading(false);

    if (response?.success) {
      setPageMessage('User account has been DELETED.');
    } else {
      setPageError('You are unable to delete this account at the moment.');
    }
  };

  const getJobRequests = async (page) => {
    setLoading(true);
    const response = await fetchAllArtisans(page + 1);
    setLoading(false);

    if (response?.success && response?.data?.data) {
      const { data } = response;
      const rows = data?.data.map((artisanData) => ({
        ...artisanData,
        name: `${artisanData?.first_name} ${artisanData?.last_name}`,
        assessments:
          artisanData?.competency_assessments?.length > 0 &&
          artisanData?.vetting_report?.length > 0 &&
          artisanData?.upskilling_reports?.length > 0
            ? 'True'
            : 'False',
        skill: artisanData?.profile?.skills?.length
          ? artisanData?.profile?.skills?.map((item) => item?.name)
          : ' _ ',
      }));

      setRowData(rows);
      setRowCount(data?.total);
    }
  };

  const getOneRequest = async (id) => {
    setLoading(true);
    let response;
    if (id) {
      response = await fetchOneArtisan(id);
    }
    setLoading(false);

    if (response?.success && response?.data) {
      setCheckArtisan(response?.data);
      setArtisanStatus(response?.success);
    }
  };

  const handleOpenArtisanDetails = (id) => {
    getOneRequest(id);
    setOpenArtisanDetails(true);
  };

  const getPage = async (page) => {
    await getJobRequests(page);
    setPage(page);
  };

  const getJobDetails = (row) => {
    navigate(`${row.id}`);
  };

  const handleExport = async () => {
    setLoading(true);

    const response = await exportUserData('artisan');

    const a = document.createElement('a');
    const date = moment().format('YYYY-MM-DD');

    a.download = `AllArtisans_export_output_${date}.csv`;
    a.href = response?.data?.report;

    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();

    setLoading(false);
  };

  useEffect(() => {
    getJobRequests(page);
  }, [page]);

  const requiredWidth = useMediaQuery('(min-width:1024px)');

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      maxWidth={{ lg: 'lg', xl: 'lg' }}
      p={5}
      minHeight="100vh"
      container
      flexGrow={1}
    >
      <Grid container>
        <Grid item lg={6}>
          <Typography variant="h5" mb={3}>
            All Artisans
          </Typography>
        </Grid>
        <Grid item lg={6}>
          <Button
            variant="outlined"
            sx={{ width: 300, float: 'right' }}
            onClick={() => handleExport()}
          >
            {' '}
            Export{' '}
          </Button>
        </Grid>
      </Grid>
      {pageError && <Alert severity="error">{pageError}</Alert>}
      {pageMessage && <Alert severity="success">{pageMessage}</Alert>}
      <Box width="100%">
        <DataGrid
          rowCount={rowCount}
          rows={rowData}
          columns={columns}
          loading={loading}
          onRowClick={(row) => handleOpenArtisanDetails(row?.row?.id)}
          pageSize={pageSize}
          onPageChange={getPage}
          page={page}
          autoHeight
          rowsPerPageOptions={[15]}
          paginationMode="server"
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
        />
        <Modal
          open={openArtisanDetails}
          onClose={handleCloseArtisanDetails}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={requiredWidth ? style : mobileStyle}>
            <Artisan artisan={checkArtisan} status={artisanStatus} />
          </Box>
        </Modal>
      </Box>
    </Box>
  );
}

export default Index;
