import { Grid } from '@mui/material';

import { Table, Loader } from '../index';

export default function Index({ locations }) {
  return (
    <Grid columnSpacing={4} container sx={{ marginLeft: '5px', width: '100%' }}>
      {locations?.length > 0 ? (
        <Table location={locations} headers={['Name', 'ID', 'Date Created']} />
      ) : (
        <Loader />
      )}
    </Grid>
  );
}
