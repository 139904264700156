import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Grid,
  TextField,
  MenuItem,
  Box,
  Typography,
  Alert,
  Tooltip,
} from '@mui/material';
import { DatePicker } from '@mui/lab';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ImageIcon from '@mui/icons-material/Image';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

import { createJobRequest } from '../../api';

function BusinessRequestForm({ requestTypes, serviceType, locations }) {
  const navigate = useNavigate();
  const [schedule, setSchedule] = useState(
    moment().format('YYYY-MM-DD HH:mm:ss')
  );
  const [pageError, setPageError] = useState(false);
  const [error, setError] = useState('');
  const [formValues, setFormValues] = useState([
    {
      service_type: serviceType,
      request_type_id: '',
      location_id: '',
      location_address: '',
      schedule: schedule,
      description: '',
      file: [],
    },
  ]);

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    if (e.target.name === 'schedule') {
      newFormValues[i][e.target?.name] = moment(e.target.value).format(
        'YYYY-MM-DD HH:mm:ss'
      );
    } else {
      newFormValues[i][e.target?.name] =
        e.target.type === 'file' ? e.target.files[0] : e.target?.value;
      setFormValues(newFormValues);
    }
  };

  let addFormFields = () => {
    setFormValues([
      ...formValues,
      {
        service_type: serviceType,
        request_type_id: '',
        location_id: '',
        schedule: schedule,
        location_address: '',
        description: '',
        file: [],
      },
    ]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const removeFile = (index, element) => {
    let newFormValues = [...formValues];
    newFormValues[index].file = [];
    setFormValues(newFormValues);
  };

  let handleSubmit = (event) => {
    event.preventDefault();
    callCreateJobRequest(formValues);
  };

  const callCreateJobRequest = (requests) => {
    createJobRequest(requests).then((response) => {
      if (response?.success) {
        navigate(`/dashboard`);
      } else {
        setPageError(true);
        setError(
          response?.exception_message.replace(/job_request\.\d{1}\./g, '')
        );
      }
    });
  };

  useEffect(() => {
    const alert = setTimeout(() => setPageError(false), 3000);
    return () => {
      clearTimeout(alert);
    };
  }, [pageError]);
  return (
    <form onSubmit={handleSubmit}>
      {formValues.map((element, index) => (
        <>
          <Grid mt={5} columnSpacing={4} key={index} container>
            <Grid xs={12} mb={3} lg={6} item>
              <TextField
                variant="standard"
                id="contact_name"
                label="Contact Person Name"
                name="contact_name"
                required
                fullWidth
              />
            </Grid>
            <Grid xs={12} mb={3} lg={6} item>
              <DatePicker
                label="Schedule"
                id="schedule"
                name="schedule"
                value={element.schedule}
                minDate={moment().toDate()}
                onChange={(newValue) => {
                  setSchedule(() => newValue);
                  handleChange(index, {
                    target: {
                      name: 'schedule',
                      value: moment(newValue).format('YYYY-MM-DD HH:mm:ss'),
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    autoComplete="off"
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid columnSpacing={4} container>
            <Grid xs={12} mb={3} lg={6} item>
              <TextField
                variant="standard"
                id="phone"
                label="Contact Person Phone Number"
                required
                fullWidth
              />
            </Grid>
            <Grid xs={12} mb={3} lg={6} item>
              <TextField
                variant="standard"
                name="request_type_id"
                label="Job Request Type"
                value={element.request_type_id}
                onChange={(e) => handleChange(index, e)}
                select
                required
                fullWidth
              >
                {requestTypes.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid columnSpacing={4} container>
            <Grid xs={12} mb={4} md={6} item>
              <TextField
                variant="standard"
                name="location_address"
                label="Enter Your Address"
                value={element.location_address}
                onChange={(e) => handleChange(index, e)}
                required
                fullWidth
              ></TextField>
            </Grid>
            <Grid xs={12} mb={3} lg={6} item>
              <TextField
                variant="standard"
                name="location_id"
                label="Enter Your Location"
                value={element.location_id}
                onChange={(e) => handleChange(index, e)}
                select
                required
                fullWidth
              >
                {locations.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>

          <Grid columnSpacing={4} container>
            <Grid xs={12} mb={3} lg={6} item>
              <TextField
                variant="standard"
                id="website"
                label="Business Website"
                name="business_website"
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid xs={12} mt={4} item>
            <TextField
              variant="outlined"
              id="description"
              label="Job Description"
              rows={4}
              name="description"
              value={element.description}
              onChange={(e) => handleChange(index, e)}
              multiline
              required
              fullWidth
            />
          </Grid>

          {element.file.length !== 0 ? (
            <Grid my={3} columnSpacing={6} px={4} container>
              <Grid xs={12} position="relative" item>
                <Tooltip title={element.file.name}>
                  <ImageIcon color="primary" sx={{ fontSize: '96px' }} />
                </Tooltip>
                <br />
                <Typography component="small">
                  {element.file.name.substring(0, 14)}
                  {element.file.name.length > 14 && '...'}
                  <DeleteIcon
                    color="primary"
                    onClick={() => removeFile(index, element)}
                  />
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid xs={12} mt={2} position="relative" item>
              <TextField
                type="file"
                id="file"
                sx={{ opacity: 0, zIndex: 100, cursor: 'pointer' }}
                name="file"
                onChange={(e) => handleChange(index, e)}
                fullWidth
              />
              <Box
                position="absolute"
                top={0}
                minHeight="100%"
                sx={{ cursor: 'pointer', display: 'flex' }}
                alignItems="center"
              >
                <CloudUploadOutlinedIcon />
                <Typography marginLeft={1}>
                  Upload Image / Video of Job
                </Typography>
              </Box>
            </Grid>
          )}

          <Grid my={3} columnSpacing={6} px={4} container>
            <Grid xs={12} mb={2} sm={6} item>
              {index >= 0 && (
                <Button
                  variant="text"
                  fontWeight="bold"
                  onClick={() => addFormFields()}
                >
                  <AddCircleIcon /> Add more job request
                </Button>
              )}
            </Grid>
            <Grid xs={12} mb={2} sm={6} item>
              {index ? (
                <Button
                  variant="text"
                  fontWeight="bold"
                  onClick={() => removeFormFields(index)}
                >
                  <DeleteIcon /> Remove Job Request
                </Button>
              ) : null}
            </Grid>
            <Grid xs={10} marginX="auto">
              {pageError && <Alert severity="error">{error}</Alert>}
            </Grid>
          </Grid>
        </>
      ))}

      <Grid my={4} maxWidth={{ sm: '100%', lg: '50%' }} mx="auto">
        <Button type="submit" size="large" variant="contained" fullWidth>
          Place Request
        </Button>
      </Grid>
    </form>
  );
}

export default BusinessRequestForm;
