import axios from 'axios';
import config from '../config';

const { baseURL } = config.api;

export async function addNewLocation(data) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios.post(`${baseURL}/admin/locations`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'An error occurred, Please contact system administrator'
            : data.message,
      },
      errors: data.data,
    };
  }
}

export async function deleteLocation(row) {
  const token = localStorage.getItem('token');

  const data = {
    name: row.name,
  };
  const headers = {
    Authorization: `Bearer ${JSON.parse(token)}`,
  };
  try {
    const response = await axios.delete(
      `${baseURL}/admin/locations/${row.id}`,
      { headers, data }
    );

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'An error occurred, Please contact system administrator'
            : data.message,
      },
      errors: data.data,
    };
  }
}

export async function deleteRequestType(row) {
  const token = localStorage.getItem('token');

  const data = {
    name: row.name,
  };
  const headers = {
    Authorization: `Bearer ${JSON.parse(token)}`,
  };
  try {
    const response = await axios.delete(
      `${baseURL}/admin/request-types/${row.id}`,
      { headers, data }
    );

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'An error occurred, Please contact system administrator'
            : data.message,
      },
      errors: data.data,
    };
  }
}

export async function addNewRequestType(data) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios.post(`${baseURL}/admin/request-types`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'An error occurred, Please contact system administrator'
            : data.message,
      },
      errors: data.data,
    };
  }
}

export async function updateLocation(id, data) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios.put(`${baseURL}/admin/locations/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'An error occurred, Please contact system administrator'
            : data.message,
      },
      errors: data.data,
    };
  }
}

export async function updateRequestType(id, data) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios.put(
      `${baseURL}/admin/request-types/${id}`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      }
    );

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'An error occurred, Please contact system administrator'
            : data.message,
      },
      errors: data.data,
    };
  }
}
