import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Box } from '@mui/material';
import { DataGrid } from '../components';

import { fetchAllArtisans } from '../api';

function Index() {
  const [pageSize, setPageSize] = useState(15);
  const [rowCount, setRowCount] = useState(15);

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([]);

  const navigate = useNavigate();

  const columns = [
    { field: 'name', headerName: "Artisan's Name", width: 200 },
    {
      field: 'email',
      headerName: "Artisan's Email",
      width: 350,
    },
    {
      field: 'assessments',
      headerName: 'Assessed',
      description: 'date',
      width: 160,
    },
    {
      field: 'vetted',
      headerName: 'Vetted',
      width: 160,
    },
    {
      field: 'upskilled',
      headerName: 'Upskilled',
      width: 160,
    },
  ];

  const getJobRequests = async (page) => {
    setLoading(true);
    const response = await fetchAllArtisans(page + 1);
    setLoading(false);

    if (response?.success && response?.data?.data) {
      const { data } = response;
      const rows = data?.data.map((artisanData) => ({
        ...artisanData,
        name: `${artisanData?.first_name} ${artisanData?.last_name}`,
        assessments:
          artisanData?.competency_assessments?.length > 0 ? 'True' : 'False',
        vetted: artisanData?.vetting_report?.length > 0 ? 'True' : 'False',
        upskilled:
          artisanData?.upskilling_reports?.length > 0 ? 'True' : 'False',
      }));

      setRowData(rows);
      setRowCount(data?.total);
    }
  };

  const getPage = async (page) => {
    await getJobRequests(page);
    setPage(page);
  };

  const makeAssessments = (row) => {
    localStorage.setItem(`artisan_${row?.id}`, JSON.stringify(row?.row));
    navigate(`${row?.id}`);
  };

  useEffect(() => {
    getJobRequests(page);
  }, [page]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      maxWidth={{ lg: 'lg', xl: 'lg' }}
      p={5}
      minHeight="100vh"
      container
      flexGrow={1}
    >
      <Typography variant="h5" mb={3}>
        All Artisans
      </Typography>
      <Box width="100%">
        <DataGrid
          rowCount={rowCount}
          rows={rowData}
          columns={columns}
          loading={loading}
          onRowClick={makeAssessments}
          pageSize={pageSize}
          onPageChange={getPage}
          page={page}
          autoHeight
          rowsPerPageOptions={[15]}
          paginationMode="server"
          getRowClassName={(params) =>
            params?.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
        />
      </Box>
    </Box>
  );
}

export default Index;
