import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import {
  Grid,
  Box,
  Typography,
  Accordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
} from '@mui/material';
import { Loader } from '../index';
import { ArrowForwardIosSharp } from '@mui/icons-material';
import Competency from './competency';
import Upskill from './Upskill';
import Vetting from './Vetting';

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function Index() {
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      {loading && <Loader />}
      <Grid minHeight="100vh" container>
        <Grid sm={8} lg={7} mt={{ lg: 4 }} marginX="auto" container p={3}>
          <Box width="100%" minHeight="90vh">
            <Accordion
              expanded={expanded === 'panel1'}
              onChange={handleChange('panel1')}
              mb={1}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography m={1}>Competency Assessment</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Competency />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === 'panel2'}
              onChange={handleChange('panel2')}
              mb={2}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography m={1}>Upskilling Report</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Upskill />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === 'panel3'}
              onChange={handleChange('panel3')}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography m={1}>Vetting Report</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Vetting />
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Index;
