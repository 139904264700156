import { useState, useEffect, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Grid, TextField, Button, Alert, Typography } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Loader from '../../Loader';
import moment from 'moment';
import { createCompetency, updateCompetency } from '../../../api';

export default function Index() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [competencyData, setCompetencyData] = useState();
  const [pageMessage, setPageMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState([]);

  const artisanData = localStorage.getItem(`artisan_${id}`);
  const storedAssessment = localStorage.getItem(`competency_${id}`);
  let assessorData = localStorage.getItem(`user`);
  assessorData = JSON.parse(assessorData);

  const {
    register,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    // defaultValues: useMemo(() => {
    //   return competencyData
    // }, [competencyData])
  });

  const onSubmit = async (data) => {
    setLoading(true);
    let response;
    if (!competencyData) {
      response = await createCompetency({
        ...data,
        artisan_id: id,
        assessment_date: moment(data.dateInput).format('YYYY-MM-DD'),
      });
    } else {
      response = await updateCompetency(
        {
          ...data,
          artisan_id: id,
          assessment_date: moment(data.dateInput).format('YYYY-MM-DD'),
        },
        competencyData?.id
      );
    }
    setLoading(false);

    if (!response?.success) {
      Object.keys(response?.data).map((key, i) => {
        const error = response?.data[key][0];
        setErrorMessage(error);
        return <li>{error}</li>;
      });
    }

    if (response?.success) {
      setPageMessage(response?.message);
      localStorage.setItem(`competency_${id}`, JSON.stringify(response?.data));
      window.location.reload();
    }
  };

  useEffect(() => {
    let data;
    if (artisanData) {
      data = JSON.parse(artisanData);
      setCompetencyData(data?.competency_assessments[0]);
    }
    if (storedAssessment) {
      data = JSON.parse(storedAssessment);
      setCompetencyData(data);
    }
  }, [artisanData, storedAssessment]);

  useEffect(() => {
    reset(competencyData);
  }, [competencyData]);

  useEffect(() => {
    const alert = setTimeout(() => setErrorMessage(false), 6000);
    return () => {
      clearTimeout(alert);
    };
  }, [errorMessage]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {loading && <Loader />}
      <Grid container>
        <Grid item lg={12}>
          {' '}
          {pageMessage && <Alert severity="success">{pageMessage}</Alert>}{' '}
          {errorMessage.length > 0 && (
            <Alert severity="error">{errorMessage}</Alert>
          )}{' '}
        </Grid>
        <Grid item lg={6} p={1}>
          <TextField
            label="Skill"
            variant="standard"
            fullWidth
            InputLabelProps={{ shrink: true }}
            {...register('skill', {
              required: {
                value: true,
                message: 'Field is required',
              },
            })}
          />

          <Typography variant="small" component="small" color="error">
            {errors.skill?.message}
          </Typography>
        </Grid>
        <Grid item lg={6} p={1}>
          <TextField
            label="Assessor Name"
            variant="standard"
            fullWidth
            value={`${assessorData.first_name} ${assessorData.last_name}`}
            InputLabelProps={{ shrink: true }}
            {...register('assessor_name', {
              required: {
                value: true,
                message: 'Field is required',
              },
            })}
          />
          <Typography variant="small" component="small" color="error">
            {errors.assessor_name?.message}
          </Typography>
        </Grid>
        <Grid item lg={6} p={1}>
          <Controller
            control={control}
            name="dateInput"
            render={({ field }) => (
              <DatePicker
                placeholderText={'Enter Assessment Date'}
                onChange={(date) => field.onChange(date)}
                selected={
                  field.value
                    ? field.value
                    : Date.parse(competencyData?.assessment_date)
                }
                maxDate={moment().toDate()}
                customInput={
                  <TextField
                    variant="standard"
                    autoComplete="off"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="Assessment Date"
                    {...register('dateInput', {
                      required: {
                        value: true,
                        message: 'Field is required',
                      },
                    })}
                  />
                }
              />
            )}
          />
          <Typography variant="small" component="small" color="error">
            {errors.dateInput?.message}
          </Typography>
        </Grid>
        <Grid item lg={6} p={1}>
          <TextField
            label="Assessment Location"
            variant="standard"
            fullWidth
            InputLabelProps={{ shrink: true }}
            {...register('assessment_venue', {
              required: {
                value: true,
                message: 'Field is required',
              },
            })}
          />
          <Typography variant="small" component="small" color="error">
            {errors.assessment_venue?.message}
          </Typography>
        </Grid>
        <Grid item lg={6} p={1}>
          <TextField
            label="Assessment Comments"
            variant="standard"
            fullWidth
            InputLabelProps={{ shrink: true }}
            {...register('assessor_comments', {
              required: {
                value: true,
                message: 'Field is required',
              },
            })}
          />
          <Typography variant="small" component="small" color="error">
            {errors.assessor_comments?.message}
          </Typography>
        </Grid>
        <Grid item lg={6} p={1}>
          <TextField
            label="Assessment Recommendations"
            variant="standard"
            fullWidth
            InputLabelProps={{ shrink: true }}
            {...register('assessor_recommendation', {
              required: {
                value: true,
                message: 'Field is required',
              },
            })}
          />
          <Typography variant="small" component="small" color="error">
            {errors.assessor_recommendation?.message}
          </Typography>
        </Grid>

        <Grid item lg={12} margin="12px" p={3}>
          <Button
            size="large"
            variant={loading ? 'variant' : 'contained'}
            fullWidth
            type="submit"
            // loading={loading}
            loadingIndicator="Loading..."
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
