import axios from 'axios';
import config from '../config';

const { baseURL } = config.api;

export async function fetchUserProfile() {
  const token = localStorage.getItem('token');

  try {
    const response = await axios({
      method: '',
      url: `${baseURL}/profile`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response?.data;
  } catch (error) {
    return error.response.data;
  }
}
