import axios from 'axios';
import config from '../config';

const { baseURL } = config.api;

export async function deactivateOneArtisan(id) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios({
      method: 'PATCH',
      url: `${baseURL}/admin/deactivate/${id}`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function activateOneArtisan(id) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios({
      method: 'PATCH',
      url: `${baseURL}/admin/reactivate/${id}`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function deleteOneArtisan(id) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios({
      method: 'DELETE',
      url: `${baseURL}/admin/delete/${id}`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}
