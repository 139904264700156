import axios from 'axios';
import config from '../config';

const { baseURL } = config.api;

export async function fetchAllAdmins() {
  const token = localStorage.getItem('token');

  try {
    const response = await axios.get(`${baseURL}/admin/all`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'An error occurred, Please contact system administrator'
            : data.message,
      },
      errors: data.data,
    };
  }
}

export async function deleteAdmin(row) {
  const token = localStorage.getItem('token');
  const data = {
    name: row.name,
  };
  const headers = {
    Authorization: `Bearer ${JSON.parse(token)}`,
  };
  try {
    const response = await axios.delete(`${baseURL}/admin/${row.id}`, {
      headers,
      data,
    });

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'An error occurred, Please contact system administrator'
            : data.message,
      },
      errors: data.data,
    };
  }
}

export async function updateAdmin(id, data) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios.patch(`${baseURL}/admin/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'An error occurred, Please contact system administrator'
            : data.message,
      },
      errors: data.data,
    };
  }
}

export async function createAdmin(data) {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.post(`${baseURL}/admin/create`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'An error occurred, Please contact system administrator'
            : data.message,
      },
      errors: data.data,
    };
  }
}

export async function fetchAvailableRoles() {
  const token = localStorage.getItem('token');

  try {
    const response = await axios.get(`${baseURL}/admin/roles`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'An error occurred, Please contact system administrator'
            : data.message,
      },
      errors: data.data,
    };
  }
}
