import axios from 'axios';
import config from '../config';

const { baseURL } = config.api;

export async function getReference(amount, id) {
  const token = localStorage.getItem('token');

  let data = {
    amount: amount,
  };

  try {
    const response = await axios.post(
      `${baseURL}/transaction/reference/${id}`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      }
    );

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'We are unable to reset your password now. Please try again or contact us support@fundis.com'
            : data.message,
      },
      errors: data.data,
    };
  }
}
