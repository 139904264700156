import { React, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useTheme, styled } from '@mui/material/styles';
import moment from 'moment';
import {
  Box,
  Tooltip,
  Typography,
  Card,
  Modal,
  Alert,
  Grid,
  CardContent,
  TextField,
  Button,
  MenuItem,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Delete, SettingsOutlined } from '@mui/icons-material';
import { deleteAdmin, updateAdmin } from '../../api';
import { Loader } from '../index';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const style = {
  position: 'absolute',
  top: '50%',
  padding: 10,
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 2,
  pt: 2,
  px: 4,
  pb: 3,
  textAlign: 'center',
};

export default function Index(rowBody) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [rows, setRows] = useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [pageError, setPageError] = useState();
  const [itemId, setItemId] = useState();
  const [selectedRole, setSelectedRole] = useState('');
  const [pageMessage, setPageMessage] = useState('');

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - rowBody?.roles?.length)
      : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const userType = localStorage.getItem('user-role');

  const handleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setRows(rowBody?.roles);
  }, [rowBody]);

  const handleDeleteItem = async (row) => {
    const check = JSON.parse(localStorage.getItem('user'));
    if (row?.email === check?.email) {
      toast.error(`You can not delete this user`);
    } else {
      setLoading(true);
      const response = await deleteAdmin(row);
      setLoading(false);
      if (response?.error) {
        const { error } = response;
        setPageError(error?.message);
        return;
      }

      if (!response?.success || response?.message) {
        setPageError(response?.message);
      }
      if (response?.success) {
        setPageMessage(`Successfully deleted ${row.firstName} `);
        setOpen(false);
      }
      window.location.reload();
    }
  };

  const handleUpdateItem = async (data) => {
    setLoading(true);
    let response;
    if (itemId && data) {
      response = await updateAdmin(itemId, { ...data, role: selectedRole });
    }
    setLoading(false);

    if (response?.error) {
      const { error } = response;
      setPageError(error?.message);
      return;
    }

    if (!response?.success || response?.message) {
      setPageError(response?.message);
    }
    if (response?.success) {
      setPageMessage('Successfully created a new location');
      setOpen(false);
    }
    window.location.reload();
  };

  const openUpdateModal = (id) => {
    setItemId(id);
    setOpen(true);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: 'rgba(255, 83, 24, 0.08)',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  }));

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {userType === `"super-admin"` ? (
        <>
          {loading && <Loader />}
          <ToastContainer />
          <TableContainer
            component={Paper}
            border={2}
            sx={{ minHeight: '100vh', width: { lg: '1000px', md: 600 } }}
          >
            {rowBody?.roles?.length && (
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {rowBody?.headers.map((column) => (
                      <StyledTableCell>
                        <Typography
                          variant="h6"
                          sx={{ fontSize: 16, fontWeight: 'bold' }}
                        >
                          {column}
                        </Typography>
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? rowBody?.roles?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : rowBody?.roles
                  ).map((row) => (
                    <StyledTableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.first_name} {row.last_name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.phone}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.email}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.roles[0]?.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {moment(row.created_at).format('ll')}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Tooltip title="Delete">
                          <IconButton disabled={userType !== `"super-admin"`}>
                            <Delete onClick={() => handleDeleteItem(row)} />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Tooltip title="Update">
                          <IconButton disabled={userType !== `"super-admin"`}>
                            <SettingsOutlined
                              disabled
                              onClick={() => openUpdateModal(row.id)}
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </StyledTableRow>
                  ))}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[15, 25, { label: 'All', value: -1 }]}
                      colSpan={3}
                      count={rowBody.roles.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            )}
          </TableContainer>
          <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Card
              sx={{ ...style, width: { lg: 600, md: 300 }, padding: 'auto' }}
            >
              {pageError && <Alert severity="error">{pageError}</Alert>}
              {pageMessage && <Alert severity="success">{pageMessage}</Alert>}
              <Grid mb={1}>
                {
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    fontWeight="bold"
                    component="span"
                    color="primary"
                  >
                    Update
                  </Typography>
                }
              </Grid>
              <form onSubmit={handleSubmit(handleUpdateItem)}>
                <CardContent sx={{ padding: 5 }}>
                  {/* <TextField
                variant='standard'
                label='Name'
                required={true}
                sx={{ width: '60%' }}
                {...register('name', {
                  required: {
                    value: true,
                    message: 'Field is required'
                  }
                })}
              />
              <Box mt={1}>
                <Typography variant='small' component='small' color='error'>
                  {' '}
                  {errors.location?.message || errors?.requestType?.message}
                </Typography>
              </Box> */}
                  <div>
                    <TextField
                      variant="standard"
                      name="role"
                      label="Admin Role"
                      value={selectedRole}
                      onChange={(e) => handleChange(e)}
                      select
                      required
                      fullWidth
                    >
                      {rowBody?.availableRoles?.map((option) => (
                        <MenuItem key={option.id} value={option.name}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </CardContent>
                <Grid mt={3}>
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    sx={{ width: 300, margin: 'auto' }}
                  >
                    Submit
                  </Button>
                </Grid>
              </form>
            </Card>
          </Modal>
        </>
      ) : (
        <>
          <Typography>Information not available to you</Typography>
        </>
      )}
    </>
  );
}
