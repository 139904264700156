import { Font, StyleSheet } from '@react-pdf/renderer';
import NunitoBold from './pdffonts/NunitoBold.ttf';
import NunitoExtraBold from './pdffonts/NunitoExtraBold.ttf';
import NunitoRegular from './pdffonts/NunitoRegular.ttf';
import NunitoMedium from './pdffonts/NunitoMedium.ttf';

Font.register({
  family: 'Nunito',
  fonts: [
    {
      src: NunitoMedium,
      fontWeight: 'normal',
      format: 'truetype',
    },
    {
      src: NunitoBold,
      fontWeight: 'bold',
      format: 'truetype',
    },
    {
      src: NunitoExtraBold,
      fontWeight: 900,
      format: 'truetype',
    },
  ],
});

export const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  titlel: {
    fontSize: 24,
    color: '#FF5318',
    fontFamily: 'Nunito',
    fontWeight: '600',
  },
  titler: {
    fontSize: 24,
    fontFamily: 'Nunito',
    fontWeight: '600',
    marginLeft: 10,
  },
  mainname: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    width: '100%',
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
    marginTop: 10,
  },
  name: {
    fontSize: 18,
    margin: 12,
  },
  subtitle: {
    fontSize: 14,
    margin: 8,
    marginTop: 3,
    fontFamily: 'Nunito',
  },
  oneproject: {
    marginBottom: 15,
  },
  etitle: {
    fontSize: 14,
    margin: 12,
    marginTop: 5,
    marginBottom: 0,
    fontFamily: 'Nunito',
  },
  edusubtitle: {
    marginBottom: 12,
    marginLeft: 12,
    fontSize: 12,
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Nunito',
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  container: {
    flexDirection: 'row',
    borderTopWidth: 2,
    borderTopColor: '#FF5318',
    borderTopStyle: 'solid',
    alignItems: 'stretch',
    paddingTop: 10,
  },
  item: {
    flexDirection: 'row',
    marginBottom: 12,
    width: '100%',
    marginLeft: 12,
  },
  bulletPoint: {
    width: 10,
    fontSize: 10,
  },
  itemContent: {
    width: '100%',
    fontSize: 14,
  },
});
