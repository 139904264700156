import axios from 'axios';
import config from '../config';

const { baseURL } = config.api;

export async function fetchAllCustomers(pageNo) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios({
      method: '',
      url: `${baseURL}/admin/users/customer?page=${pageNo}`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchOneCustomer(id) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios({
      method: '',
      url: `${baseURL}/admin/users/customer/${id}`,
      // url: `${baseURL}/admin/customers/${id}`, check up postman endpoint, this is returning error 500
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function reassignJob(job_id, artisan_id) {
  const token = localStorage.getItem('token');

  const data = {
    artisan_id,
  };
  try {
    const response = await axios({
      method: 'patch',
      data,
      url: `${baseURL}/admin/job-request/${job_id}/reassign`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? data.message
            : 'We are unable to reassign/assign an artisan now. Please contact the tech team',
      },
      errors: data.data,
    };
  }
}

export async function fetchOneArtisan(id) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios({
      method: '',
      url: `${baseURL}/admin/users/artisan/${id}`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchAllArtisans(pageNo) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios({
      method: '',
      url: `${baseURL}/admin/users/artisan?page=${pageNo}`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchAllQuotations(pageNo) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios({
      method: '',
      url: `${baseURL}/admin/quotations?status=pending&page=${pageNo}`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchAllSuccessReceipts(pageNo) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios({
      method: '',
      url: `${baseURL}/admin/transactions?status=SUCCESSFUL`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchAllReceipts(pageNo) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios({
      method: '',
      url: `${baseURL}/admin/transactions?page=${pageNo}`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function deactivateOneCustomer(id) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios({
      method: 'PATCH',
      url: `${baseURL}/admin/deactivate/${id}`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function activateOneCustomer(id) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios({
      method: 'PATCH',
      url: `${baseURL}/admin/reactivate/${id}`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function deleteOneCustomer(id) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios({
      method: 'DELETE',
      url: `${baseURL}/admin/delete/${id}`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}
