import axios from 'axios';
import config from '../config';

const { baseURL } = config.api;

export async function getOneClaim(id) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios.get(
      `${baseURL}/admin/insurance/${id}/artisan`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      }
    );

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'We are unable to get any insurance claims now. Please try again or contact us support@fundis.com'
            : data.message,
      },
      errors: data.data,
    };
  }
}

export async function getAllClaims(pageNo) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios({
      method: '',
      url: `${baseURL}/admin/insurance?page=${pageNo}`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}
