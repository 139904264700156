import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Modal, Grid, Button } from '@mui/material';
import moment from 'moment';
import { DataGrid, Loader } from '../components';

import { fetchAllJobRequests, exportAdminData, fetchAllArtisans } from '../api';
import { fetchOneJobRequest } from '../api/jobRequest';
import SingleJobDetail from '../components/Dashboard/SingleJobDetail';
import useMediaQuery from '@mui/material/useMediaQuery';

function Index() {
  const [pageSize, setPageSize] = useState(15);
  const [rowCount, setRowCount] = useState(15);

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([]);

  const [openJobDetails, setOpenJobDetails] = useState(false);
  const [checkJob, setCheckJob] = useState([]);
  const [availableArtisans, setAvailableArtisans] = useState();

  const handleCloseJobDetails = () => setOpenJobDetails(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    maxHeight: '70vh',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
    textAlign: 'left',
  };

  const mobileStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    minHeight: '30vh',
    maxHeight: '70vh',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    margin: '10px',
    borderRadius: 2,
    boxShadow: 24,
    p: 2,
  };

  const navigate = useNavigate();

  const columns = [
    { field: 'job_key', headerName: 'Job ID', width: 200 },
    { field: 'title', headerName: 'Job Title', width: 160 },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
    },
    {
      field: 'location',
      headerName: 'Location',
      width: 160,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 200,
    },
    {
      field: 'date',
      headerName: 'Date Created',
      description: 'date',
      width: 160,
    },
  ];

  const fetchArtisans = async () => {
    let allArtisans = [];
    setLoading(true);
    let res = await fetchAllArtisans(1);
    let page = 1;
    while (page <= res?.data?.last_page) {
      let res = await fetchAllArtisans(page);
      page++;
      allArtisans = allArtisans.concat(res?.data?.data);
    }
    setAvailableArtisans(allArtisans);
    setLoading(false);
  };

  const getJobRequests = async (page) => {
    setLoading(true);
    const response = await fetchAllJobRequests(page + 1);
    fetchArtisans();

    if (response?.success && response?.data?.data) {
      const { data } = response;
      const rows = data?.data.map((requestData) => ({
        ...requestData,
        location: requestData?.location?.name,
        title: requestData?.request_type?.name,
        date: moment(requestData?.created_at).format('ll'),
      }));
      setLoading(false);
      setRowData(rows);
      setRowCount(data?.total);
    }
  };
  const getPage = async (page) => {
    await getJobRequests(page);
    setPage(page);
  };

  const getJobDetails = (row) => {
    navigate(`${row?.id}`);
  };

  const getOneRequest = async (id) => {
    setLoading(true);
    let response;
    if (id) {
      response = await fetchOneJobRequest(id);
    }
    setLoading(false);

    if (response?.success && response?.data) {
      setCheckJob(response?.data);
    }
  };

  const handleOpenJobDetails = async (id) => {
    if (id) {
      await getOneRequest(id);
    }
    setOpenJobDetails(true);
  };

  const handleExport = async () => {
    setLoading(true);

    const response = await exportAdminData('job-request');

    const a = document.createElement('a');
    const date = moment().format('YYYY-MM-DD');

    a.download = `AllJobRequest_export_output_${date}.csv`;
    a.href = response?.data?.report;

    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();

    setLoading(false);
  };

  useEffect(() => {
    getJobRequests(page);
  }, [page]);

  const requiredWidth = useMediaQuery('(min-width:1024px)');

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        maxWidth={{ lg: 'lg', xl: 'lg' }}
        p={5}
        minHeight="100vh"
        container
        flexGrow={1}
      >
        {/* <ToastContainer/> */}
        <Grid container>
          <Grid item lg={6}>
            <Typography variant="h5" mb={3}>
              All Job Requests
            </Typography>
          </Grid>
          <Grid item lg={6} sm={4} md={4}>
            <Button
              // pb={2}
              variant="outlined"
              sx={{ width: 300, float: 'right' }}
              onClick={() => handleExport()}
            >
              Export
            </Button>
          </Grid>
        </Grid>
        <Box width="100%">
          <DataGrid
            rowCount={rowCount}
            rows={rowData}
            columns={columns}
            loading={loading}
            // onRowClick={getJobDetails}
            onRowClick={(row) => handleOpenJobDetails(row?.id)}
            pageSize={pageSize}
            onPageChange={getPage}
            page={page}
            autoHeight
            rowsPerPageOptions={[15]}
            paginationMode="server"
            getRowClassName={(params) =>
              params?.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
          />
          <Modal
            open={openJobDetails}
            onClose={handleCloseJobDetails}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={requiredWidth ? style : mobileStyle}>
              <SingleJobDetail job={checkJob} allArtisans={availableArtisans} />
            </Box>
          </Modal>
        </Box>
      </Box>
    </>
  );
}

export default Index;
