import axios from 'axios';
import config from '../config';

const { baseURL } = config.api;

export async function createJobRequest(jobRequestData) {
  let formData = new FormData();

  for (let i = 0; i < jobRequestData.length; i++) {
    const request = jobRequestData[i];

    formData.append(`job_request[${i}][service_type]`, request.service_type);
    formData.append(
      `job_request[${i}][request_type_id]`,
      request.request_type_id
    );
    formData.append(`job_request[${i}][schedule]`, request.schedule);
    formData.append(`job_request[${i}][description]`, request.description);
    formData.append(`job_request[${i}][location_id]`, request.location_id);
    formData.append(`job_request[${i}][address]`, request.location_address);
    formData.append(`job_request[${i}][file]`, request.file);
  }

  const token = localStorage.getItem('token');

  try {
    const response = await axios({
      method: 'post',
      url: `${baseURL}/customer/job-request`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function getJobRequests() {
  const token = localStorage.getItem('token');

  try {
    const response = await axios({
      method: '',
      url: `${baseURL}/request-types`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchAllJobRequests(pageNo) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios({
      method: '',
      url: `${baseURL}/admin/job-request?page=${pageNo}`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchOneJobRequest(id) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios({
      method: '',
      url: `${baseURL}/admin/job-request/${id}`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchJobRequestPagination(url) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios({
      method: '',
      url: url,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response?.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function fetchJobRequest(userId) {
  const token = localStorage.getItem('token');
  try {
    const response = await axios({
      method: '',
      url: `${baseURL}/customer/job-request/${userId}`,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    });
    return response?.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function getRequestTypes() {
  try {
    const response = await axios(`${baseURL}/request-types`);
    return response.data.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function getLocations() {
  try {
    const response = await axios(`${baseURL}/locations`);
    return response.data.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function getArtisanProfile(artisanID) {
  try {
    const response = await axios(`${baseURL}/${artisanID}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}
